import './info-icon-popup.css';

import React, { useRef, useState } from 'react';

import Popup from '../popup';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const InfoIconPopup = ({ popupText, className, small,maxWidth = '350px' }) => {
	const [showInfo, setShowInfo] = useState({
		visible: false,
		ref: null,
		current: null
	});

	const infoRef = useRef(null);

	return (
		<>
			<span
				onMouseEnter={() =>
					setShowInfo({
						visible: true,
						ref: infoRef,
						content: popupText
					})
				}
				onMouseLeave={(e) => setShowInfo({ visible: false })}
				ref={infoRef}
				className={classnames('icon usp-info-icon', {
					active: showInfo.visible,
					[className]: className,
					small: small
				})}
			></span>
			<Popup
				showTelephonePopover={showInfo.visible}
				target={showInfo.ref || {}}
				placement="top-end"
			>
				<div onMouseLeave={(_) => setShowInfo({ visible: false })}>
					<div
						className="p-4 bg-white info-icon-popup col-12 col-md-3"
						style={{ maxWidth }}
					>
						<span>{showInfo.content}</span>
					</div>
				</div>
			</Popup>
		</>
	);
};

InfoIconPopup.propTypes = {
	popupText: PropTypes.string.isRequired,
	className: PropTypes.string,
	small: PropTypes.bool
};

export default InfoIconPopup;

